
import {
  defineAsyncComponent,
  defineComponent,
  ref,
  computed,
  onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { AxiosResponse } from 'axios';

/** Interfaces */
import {
  EncryptedResponseLoginI,
  LocationI,
} from '@/interfaces/auth.interface';
import {
  GetReferenceAndAgreementI,
  PlaceToPayRequestI,
  PlaceToPayResponseI,
  ResponseCovenantI,
  SendPaymentLinkI,
} from '@/interfaces/placetopay.interface';

/** Utils */
import {
  closeSwal,
  showSwalError,
  showSwalLoading,
} from '@/utils/sweetalert2-utils';
import {
  disableEncryption,
  showErrors,
  isAppleDevice,
  addMinutesToDate,
} from '@/utils/general-utils';

/** Services */
import { PayService } from '@/services/pay.service';
import { EncryptService } from '@/services/encrypt.service';

export default defineComponent({
  name: 'PaymentLink',
  components: {
    ModalPaymentChannels: defineAsyncComponent(
      () => import('@/components/shared/ModalPaymentChannels.vue')
    ),
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const dataDecrypted = ref<SendPaymentLinkI>(Object.create({}));
    const validData = ref<boolean>(true);
    const showModalPaymentChannels = ref<boolean>(false);
    const disabledPaymentChannels = ref<boolean>(false);
    const token = ref<string>('');
    const iPhoneLink = ref<unknown>({});

    const getLink = computed<string>(() => route.params.link?.toString() || '');
    const urlReturn = process.env.VUE_APP_PLACETOPAY_BASE_URL_RETURN;

    const customProperty = ref<string>('portfolio_id');

    const verifyDecoded = (
      object: SendPaymentLinkI,
      fields: string[]
    ): boolean => {
      for (const field of fields) {
        if (!(field in object)) {
          return false;
        }
      }
      return true;
    };

    const location = computed<LocationI>(() => {
      return store.state.location;
    });

    // Paso 1
    const decryptLink = (): void => {
      const errorMessage = 'El link de pago no es válido.';

      try {
        if (!getLink.value) {
          throw new Error(errorMessage);
        }

        const decrypted = EncryptService.decrypt(getLink.value, true);

        const dataParsed: SendPaymentLinkI =
          typeof decrypted === 'string' ? JSON.parse(decrypted) : decrypted;

        if (existPropertyInPaymentLink(dataParsed, 'anchor_id')) {
          customProperty.value = 'anchor_id';
        } else if (existPropertyInPaymentLink(dataParsed, 'patrimony_id')) {
          customProperty.value = 'patrimony_id';
        } else if (existPropertyInPaymentLink(dataParsed, 'covenant')) {
          customProperty.value = 'covenant';
        }

        const dataDecoded =
          dataParsed &&
          verifyDecoded(dataParsed, [
            'document_type',
            'identification',
            'register_id',
            'amount',
            'payday_limit',
            'payment_platform',
            'reference',
            'commerce',
            customProperty.value,
            'description',
            'lince',
          ]);

        if (!dataDecoded) {
          throw new Error(errorMessage);
        }

        dataDecrypted.value = dataParsed;

        getToken(customProperty.value === 'covenant');
      } catch (error) {
        validData.value = false;
        showSwalError(errorMessage).then(() =>
          window.location.assign(urlReturn)
        );
      }
    };

    const existPropertyInPaymentLink = (
      data: SendPaymentLinkI,
      property: string
    ) => {
      return Object.prototype.hasOwnProperty.call(data, property);
    };

    // Paso 2
    const getToken = (lince: boolean): void => {
      const errorMessage = 'Se produjo un error al autenticar el link de pago.';
      showSwalLoading();
      store
        .dispatch('getPublicToken', {
          date: addMinutesToDate(5),
          duration: 15,
        })
        .then((response: EncryptedResponseLoginI) => {
          if (response?.data?.access_token) {
            token.value = response?.data?.access_token;
            disabledPaymentChannels.value = false;
            if (lince) {
              closeSwal();
            } else {
              getCovenants();
            }
          } else {
            disabledPaymentChannels.value = true;
            showSwalError(errorMessage);
          }
        })
        .catch((error) => {
          disabledPaymentChannels.value = true;
          showErrors(error, errorMessage);
        });
    };

    // Paso 3
    const getCovenants = (): void => {
      type fieldType = keyof typeof dataDecrypted.value;
      const fieldName = customProperty.value as fieldType;

      new PayService()
        .getCovenantsByPortfolio(
          customProperty.value,
          String(dataDecrypted.value[fieldName]),
          token.value
        )
        .then((response: AxiosResponse) => {
          closeSwal();
          const data = response.data;
          const decrypted: ResponseCovenantI = disableEncryption()
            ? data
            : EncryptService.decrypt(data);
          const { attributes, success } = decrypted?.data;
          if (success) {
            dataDecrypted.value.covenant = attributes;
            disabledPaymentChannels.value = attributes.length === 0;
          } else {
            disabledPaymentChannels.value = true;
            showSwalError(
              'Se produjo un error al cargar los convenios para pago físico.'
            ).then(() => window.location.assign(urlReturn));
          }
        })
        .catch(() => {
          disabledPaymentChannels.value = true;
          showSwalError(
            'Se produjo un error al cargar los convenios para pago físico.'
          ).then(() => window.location.assign(urlReturn));
        });
    };

    const dataPlayToPay = computed<PlaceToPayRequestI>(() => {
      return {
        document_type: dataDecrypted.value?.document_type,
        identification: dataDecrypted.value?.identification,
        payment_platform: dataDecrypted.value?.payment_platform,
        reference: dataDecrypted.value?.reference,
        description: dataDecrypted.value?.description,
        amount: dataDecrypted.value?.amount,
        ip_address: location.value?.ip ?? '8.8.8.8',
        user_agent:
          location.value?.uag ??
          'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.107 Safari/537.36 Edg/92.0.902.62',
      };
    });

    const getReferenceAndAgreement = computed<GetReferenceAndAgreementI>(() => {
      return {
        covenant: dataDecrypted.value.covenant ?? [],
        identification: dataDecrypted.value.identification,
        obligationId: dataDecrypted.value.register_id,
        reference: dataDecrypted.value.reference,
        existCovenantAttr: customProperty.value === 'covenant',
        isNpl: customProperty.value === 'portfolio_id',
      };
    });

    const onlinePayment = () => {
      showSwalLoading();
      new PayService()
        .payFee(dataPlayToPay.value, token.value)
        .then((response: AxiosResponse) => {
          closeSwal();
          const data = response.data;
          const decrypted = disableEncryption()
            ? data
            : EncryptService.decrypt(data);
          const { attributes, message, success } =
            decrypted?.data as PlaceToPayResponseI;
          if (success) {
            const { status, processUrl } = attributes;
            if (status?.status?.toLowerCase() === 'ok') {
              isAppleDevice(iPhoneLink.value as HTMLLinkElement, processUrl);
            } else {
              showSwalError(status.message);
            }
          } else {
            showSwalError(message);
          }
        })
        .catch((error) => {
          showErrors(
            error,
            'Se produjo un error al redireccionar a la plataforma de pago online PSE.'
          );
        });
    };

    const eventModalPaymentChannels = () => {
      showModalPaymentChannels.value = true;
    };

    const closeModalPaymentChannels = () => {
      showModalPaymentChannels.value = false;
    };

    const physicalPayment = () => {
      eventModalPaymentChannels();
    };

    const getIp = () => {
      store.dispatch('getIp');
    };

    getIp();

    onMounted(() => {
      decryptLink();
    });

    return {
      dataDecrypted,
      physicalPayment,
      showModalPaymentChannels,
      eventModalPaymentChannels,
      closeModalPaymentChannels,
      validData,
      onlinePayment,
      getReferenceAndAgreement,
      disabledPaymentChannels,
      iPhoneLink,
    };
  },
});
